@charset "UTF-8";

@font-face {
  font-family: 'sofia-pro';
  src: url('./SofiaProRegular.eot');
  src: local('Sofia Pro Regular'), local('SofiaProRegular'),
    url('./SofiaProRegular.eot?#iefix') format('embedded-opentype'),
    url('./SofiaProRegular.woff2') format('woff2'),
    url('./SofiaProRegular.woff') format('woff'),
    url('./SofiaProRegular.ttf') format('truetype');

  font-weight: 500;
  font-style: normal;
}
