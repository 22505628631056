@charset "UTF-8";

@font-face {
  font-family: 'ae-marketplace';
  src: url('./ae-marketplace.eot?v=AE_MARKETPLACE_HASH1');
  src: url('./ae-marketplace.eot?v=AE_MARKETPLACE_HASH#iefix') format('embedded-opentype'),
    url('./ae-marketplace.woff?v=AE_MARKETPLACE_HASH2') format('woff'),
    url('./ae-marketplace.ttf?v=AE_MARKETPLACE_HASH3') format('truetype'),
    url('./ae-marketplace.svg?v=AE_MARKETPLACE_HASH#ae-marketplace') format('svg');

  font-weight: normal;
  font-style: normal;
}

[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'ae-marketplace' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-activity:before {
  content: '\62';
}

.icon-arrow-down:before {
  content: '\65';
}

.icon-arrow-right:before {
  content: '\66';
}

.icon-arrow-up:before {
  content: '\67';
}

.icon-bar-chart-2:before {
  content: '\68';
}

.icon-bell:before {
  content: '\69';
}

.icon-bell-off:before {
  content: '\6a';
}

.icon-bookmark:before {
  content: '\6b';
}

.icon-calendar:before {
  content: '\6c';
}

.icon-camera:before {
  content: '\6d';
}

.icon-check:before {
  content: '\6e';
}

.icon-shield:before {
  content: '\6f';
}

.icon-chevron-down:before {
  content: '\70';
}

.icon-chevron-left:before {
  content: '\71';
}

.icon-chevron-right:before {
  content: '\72';
}

.icon-chevron-up:before {
  content: '\73';
}

.icon-clock:before {
  content: '\74';
}

.icon-compass:before {
  content: '\75';
}

.icon-crop:before {
  content: '\76';
}

.icon-crosshair:before {
  content: '\78';
}

.icon-delete:before {
  content: '\79';
}

.icon-download-cloud:before {
  content: '\41';
}

.icon-edit:before {
  content: '\42';
}

.icon-heart-solid:before {
  content: '\43';
}

.icon-heart:before {
  content: '\44';
}

.icon-folder:before {
  content: '\45';
}

.icon-flag:before {
  content: '\46';
}

.icon-filter-slider:before {
  content: '\47';
}

.icon-filter:before {
  content: '\48';
}

.icon-film:before {
  content: '\49';
}

.icon-file-plus:before {
  content: '\4a';
}

.icon-file-minus:before {
  content: '\4b';
}

.icon-eye:before {
  content: '\4d';
}

.icon-external-link:before {
  content: '\4e';
}

.icon-home:before {
  content: '\50';
}

.icon-image:before {
  content: '\51';
}

.icon-info:before {
  content: '\52';
}

.icon-lock:before {
  content: '\54';
}

.icon-log-in:before {
  content: '\55';
}

.icon-log-out:before {
  content: '\56';
}

.icon-message-circle:before {
  content: '\5a';
}

.icon-message-square:before {
  content: '\30';
}

.icon-minus-circle:before {
  content: '\31';
}

.icon-share:before {
  content: '\32';
}

.icon-settings:before {
  content: '\33';
}

.icon-search:before {
  content: '\34';
}

.icon-refresh-cw:before {
  content: '\35';
}

.icon-printer:before {
  content: '\36';
}

.icon-plus-square:before {
  content: '\37';
}

.icon-plus-circle:before {
  content: '\38';
}

.icon-plus:before {
  content: '\39';
}

.icon-pie-chart:before {
  content: '\21';
}

.icon-paperclip:before {
  content: '\22';
}

.icon-notepad-tick:before {
  content: '\23';
}

.icon-move:before {
  content: '\24';
}

.icon-star:before {
  content: '\25';
}

.icon-star-solid:before {
  content: '\26';
}

.icon-thumbs-down:before {
  content: '\27';
}

.icon-thumbs-up:before {
  content: '\28';
}

.icon-trash-2:before {
  content: '\29';
}

.icon-unlock:before {
  content: '\2a';
}

.icon-users:before {
  content: '\2b';
}

.icon-x-circle:before {
  content: '\2c';
}

.icon-x-square:before {
  content: '\2d';
}

.icon-copy:before {
  content: '\2e';
}

.icon-airplay:before {
  content: '\61';
}

.icon-alert-triangle:before {
  content: '\63';
}

.icon-map-pin:before {
  content: '\58';
}

.icon-cross:before {
  content: '\77';
}

.icon-edit-2:before {
  content: '\4f';
}

.icon-arrow-left:before {
  content: '\2f';
}

.icon-hamburger:before {
  content: '\3a';
}

.icon-table:before {
  content: '\57';
}

.icon-book:before {
  content: '\3b';
}

.icon-book-open:before {
  content: '\3c';
}

.icon-mail:before {
  content: '\3d';
}

.icon-message-square-fill:before {
  content: '\3e';
}

.icon-thumbs-up-fill:before {
  content: '\3f';
}

.icon-file:before {
  content: '\4c';
}

.icon-fav-row-icon:before {
  content: '\40';
}

.icon-fav-row-icon-active:before {
  content: '\5b';
}

.icon-comment-row-icon-active:before {
  content: '\5d';
}

.icon-comment-row-icon:before {
  content: '\5e';
}

.icon-comment-row-icon-active-1:before {
  content: '\5f';
}

.icon-zap:before {
  content: '\60';
}

.icon-list:before {
  content: '\7b';
}

.icon-board:before {
  content: '\7c';
}

.icon-note:before {
  content: '\7d';
}

.icon-note-attached:before {
  content: '\7e';
}

.icon-lettings:before {
  content: '\5c';
}

.icon-sales:before {
  content: '\e000';
}

.icon-update-timer:before {
  content: '\e001';
}

.icon-send:before {
  content: '\59';
}

.icon-availability:before {
  content: '\e002';
}

.icon-layers:before {
  content: '\53';
}

.icon-download:before {
  content: '\7a';
}

.icon-phone:before {
  content: '\e003';
}

.icon-circle-check:before {
  content: '\e004';
}

.icon-circle-check-invert:before {
  content: '\e005';
}

.icon-smartphone:before {
  content: '\e006';
}

.icon-realtime:before {
  content: '\e008';
}

.icon-society:before {
  content: '\e009';
}

.icon-size:before {
  content: '\e00a';
}

.icon-thumbs-up:before {
  content: '\28';
}

.icon-megaphone:before {
  content: '\e00b';
}

.icon-desktop:before {
  content: '\e00c';
}

.icon-upload-cloud:before {
  content: '\e00d';
}

.icon-mobile:before {
  content: '\e007';
}

.icon-inbox-off:before {
  content: '\e00e';
}

.icon-reply-mail:before {
  content: '\e00f';
}

.icon-zap-fill:before {
  content: '\e010';
}

.icon-package:before {
  content: '\64';
}

.icon-maximize-2:before {
  content: '\e011';
}

.icon-alert-circle:before {
  content: '\e012';
}

.icon-summary-dash:before {
  content: '\e013';
}

.icon-smile:before {
  content: '\e014';
}

.icon-arrow-up-circle:before {
  content: '\e015';
}

.icon-link-2:before {
  content: '\e016';
}
