.mapboxgl-popup-content {
  padding: 0;
}

.mapboxgl-popup-close-button {
  position: absolute;
  top: 7px;
  right: 7px;
  border-radius: 4px;
  background-color: #fff;
}
